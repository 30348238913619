@tailwind base;
@tailwind components;
@tailwind utilities;
 //@import "../src/assets/params/css/tailwind-light/theme-artas.scss";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeicons/primeicons.css";
@font-face {
  font-family: "peace_sans";
  src: url('assets/params/fonts/peace_sans.otf') format("truetype");
}
/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar */
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines to show */
  overflow: hidden;
}
.rotate-90 {
  transform: rotate(90deg);
}
::-webkit-scrollbar-track {
  @apply bg-gray-800;
}

::-webkit-scrollbar-thumb {
  @apply  bg-gradient-to-r from-purple-500/65 to-indigo-500/65 hover:from-purple-600 hover:to-indigo-600 shadow-lg;
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}

//
//.scale02 {
//  //transform: scale(0.2)!important;
//  zoom: 85%!important;
//  z-index: 100000!important;
//
//}
//
.bg-color-custom {
   @apply bg-[#4a0620]
}
.text-color-custom {
   @apply text-white;
}
//placeholder {
//   @apply bg-[#4a0620];
//
//}
//
//
//*{
//  margin: 0px;
//  padding: 0px;
//}
//
//body{
//  font-family: 'Exo', sans-serif;
//}
//
//
//.context {
//  width: 100%;
//  position: absolute;
//  top:50vh;
//
//}
//.font-select{
//  font-family:peace_sans,serif;
//  //@apply font-family: 'peace_sans,serif';
//}
//.area{
//  width: 100%;
//}
//
//.circles{
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  overflow: hidden;
//}
//
//.circles li{
//  position: absolute;
//  display: block;
//  list-style: none;
//  width: 20px;
//  height: 20px;
//  background: rgba(0, 57, 128, 0.2);
//  animation: animate 50s linear infinite;
//  bottom: -150px;
//
//}
//
//.circles li:nth-child(1){
//  left: 25%;
//  width: 80px;
//  height: 80px;
//  animation-delay: 0s;
//}
//
//
//.circles li:nth-child(2){
//  left: 10%;
//  width: 20px;
//  height: 20px;
//  animation-delay: 2s;
//  animation-duration: 12s;
//}
//
//.circles li:nth-child(3){
//  left: 70%;
//  width: 20px;
//  height: 20px;
//  animation-delay: 4s;
//}
//
//.circles li:nth-child(4){
//  left: 40%;
//  width: 60px;
//  height: 60px;
//  animation-delay: 0s;
//  animation-duration: 18s;
//}
//
//.circles li:nth-child(5){
//  left: 65%;
//  width: 20px;
//  height: 20px;
//  animation-delay: 0s;
//}
//
//.circles li:nth-child(6){
//  left: 75%;
//  width: 110px;
//  height: 110px;
//  animation-delay: 3s;
//}
//
//.circles li:nth-child(7){
//  left: 35%;
//  width: 150px;
//  height: 150px;
//  animation-delay: 7s;
//}
//
//.circles li:nth-child(8){
//  left: 50%;
//  width: 25px;
//  height: 25px;
//  animation-delay: 15s;
//  animation-duration: 45s;
//}
//
//.circles li:nth-child(9){
//  left: 20%;
//  width: 15px;
//  height: 15px;
//  animation-delay: 2s;
//  animation-duration: 35s;
//}
//
//.circles li:nth-child(10){
//  left: 85%;
//  width: 150px;
//  height: 150px;
//  animation-delay: 0s;
//  animation-duration: 11s;
//}
//
//
//
//@keyframes animate {
//
//  0%{
//    transform: translateY(0) rotate(0deg);
//    opacity: 1;
//    border-radius: 0;
//  }
//
//  100%{
//    transform: translateY(-1000px) rotate(720deg);
//    opacity: 0;
//    border-radius: 50%;
//  }
//}
